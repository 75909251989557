
import {defineComponent, PropType} from 'vue'
import CopyButton from "@/components/utils/buttons/CopyButton.vue";

export interface XRayImageListItem {
  readonly clinicName: string
  readonly cabinetName: string
  readonly equipmentName: string
  readonly imageName: string
  readonly imageUID: string
}

export default defineComponent({
  name: "ClinicsXRayImagesListItem",
  components: {CopyButton},
  props: {
    image: {
      type: Object as PropType<XRayImageListItem>,
      required: true
    }
  },
  setup() {
    return {}
  }
})
