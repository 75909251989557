import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyButton = _resolveComponent("CopyButton")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.image.clinicName), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.image.cabinetName), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.image.equipmentName), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.image.imageName), 1),
    _createElementVNode("td", _hoisted_5, [
      _createVNode(_component_CopyButton, {
        content: _ctx.image.imageUID
      }, null, 8, ["content"])
    ])
  ]))
}