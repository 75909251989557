import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsXrayImagesListHeader = _resolveComponent("ClinicsXrayImagesListHeader")!
  const _component_ClinicsXRayImagesListBody = _resolveComponent("ClinicsXRayImagesListBody")!
  const _component_BaseTableWrapper = _resolveComponent("BaseTableWrapper")!

  return (_openBlock(), _createBlock(_component_BaseTableWrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_ClinicsXrayImagesListHeader, { onOnFilter: _ctx.onFilter }, null, 8, ["onOnFilter"]),
      _createVNode(_component_ClinicsXRayImagesListBody, { filterParams: _ctx.filterParams }, null, 8, ["filterParams"])
    ]),
    _: 1
  }))
}