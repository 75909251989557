
import {computed, defineComponent, PropType} from 'vue'
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import {useImagesStore} from "@/store/images/ImagesStore";
import ClinicsXRayImagesListItem, {XRayImageListItem} from "@/components/clinics/images/ClinicsXRayImagesListItem.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import {ClinicsXRayImagesFilerParams} from "@/components/clinics/images/ClinicsXrayImagesListHeader.vue";


export default defineComponent({
  name: "ClinicsXRayImagesListBody",
  components: {ClinicsXRayImagesListItem, BaseTableBodyWrapper},
  props: {
    filterParams: {
      type: Object as PropType<ClinicsXRayImagesFilerParams>,
      required: false
    }
  },
  setup(props) {
    const clinicsStore = useClinicsStore()
    const cabinetsStore = useCabinetsStore()
    const xRayEquipmentsStore = useXRayEquipmentsStore()
    const imagesStore = useImagesStore()

    const clinicsState = clinicsStore.getState()
    const cabinetsState = cabinetsStore.getState()
    const xRayEquipmentsState = xRayEquipmentsStore.getState()
    const imagesState = imagesStore.getState()

    const clinics = computed(() => clinicsState.clinics)
    const cabinets = computed(() => cabinetsState.cabinets)
    const equipments = computed(() => xRayEquipmentsState.xRayEquipments)
    const images = computed<XRayImageListItem[]>(() => imagesState.images.map(image => {

      const equipment = equipments.value.find(eq => eq.imageUIDs.includes(image.uid))
      const cabinet = equipment ? cabinets.value.find(cab => cab.xRayEquipmentsUID.includes(equipment.uid)) : undefined
      const clinic = cabinet ? clinics.value.find(clin => clin.cabinetsUID.includes(cabinet.uid)) : undefined
      return {
        clinicName: clinic?.name || '',
        cabinetName: cabinet?.name || '',
        equipmentName: equipment?.name || '',
        imageUID: image.uid,
        imageName: image.imageName
      }
    }))

    const imagesByClinic = computed(() => {
      if (!props.filterParams?.clinicName) return images.value
      else return images.value.filter(image => image.clinicName.toLowerCase().includes(props.filterParams?.clinicName || ''))
    })

    const imagesByCabinet = computed(() => {
      if (!props.filterParams?.cabinetName) return imagesByClinic.value
      else return imagesByClinic.value.filter(image => image.cabinetName.toLowerCase().includes(props.filterParams?.cabinetName || ''))
    })

    const imagesByEquipmentName = computed(() => {
      if (!props.filterParams?.equipmentName) return imagesByCabinet.value
      else return imagesByCabinet.value.filter(image => image.equipmentName.toLowerCase().includes(props.filterParams?.equipmentName || ''))
    })

    const imagesByName = computed(() => {
      if (!props.filterParams?.imageName) return imagesByEquipmentName.value
      else return imagesByEquipmentName.value.filter(image => image.imageName.toLowerCase().includes(props.filterParams?.imageName || ''))
    })

    const sortedImages = computed(() => {
      const imagesRef = [...imagesByName.value]
      return imagesRef.sort((a, b) => a.clinicName.localeCompare(b.clinicName))
    })

    return {sortedImages}
  }
})
