
import { defineComponent, onBeforeMount} from 'vue'

import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import {useImagesStore} from "@/store/images/ImagesStore";
import ClinicsXRayImagesList from "@/components/clinics/images/ClinicsXRayImagesList.vue";

export default defineComponent({
  name: "ClinicsXRayImages",
  components: {ClinicsXRayImagesList},
  setup() {
    const clinicsStore = useClinicsStore()
    const cabinetsStore = useCabinetsStore()
    const xRayEquipmentsStore = useXRayEquipmentsStore()
    const imagesStore = useImagesStore()

    onBeforeMount(async () => {
      await Promise.all([clinicsStore.setClinics(false), cabinetsStore.setCabinets(false), xRayEquipmentsStore.setXRayEquipments(false), imagesStore.setImages(false)])
    })
    return {}
  }
})
