
import {defineComponent, ref} from 'vue'
import BaseTableWrapper from "@/components/utils/tables/BaseTableWrapper.vue";
import ClinicsXrayImagesListHeader, {ClinicsXRayImagesFilerParams} from "@/components/clinics/images/ClinicsXrayImagesListHeader.vue";
import ClinicsXRayImagesListBody from "@/components/clinics/images/ClinicsXRayImagesListBody.vue";

export default defineComponent({
  name: "ClinicsXRayImagesList",
  components: {ClinicsXRayImagesListBody, ClinicsXrayImagesListHeader, BaseTableWrapper},
  setup() {

    const filterParams = ref<ClinicsXRayImagesFilerParams | undefined>(undefined)
    const onFilter = (params: ClinicsXRayImagesFilerParams) => filterParams.value = params
    return {onFilter, filterParams}
  }
})
