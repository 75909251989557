import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsXRayImagesListItem = _resolveComponent("ClinicsXRayImagesListItem")!
  const _component_BaseTableBodyWrapper = _resolveComponent("BaseTableBodyWrapper")!

  return (_openBlock(), _createBlock(_component_BaseTableBodyWrapper, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedImages, (image) => {
        return (_openBlock(), _createBlock(_component_ClinicsXRayImagesListItem, {
          key: image.uid,
          image: image
        }, null, 8, ["image"]))
      }), 128))
    ]),
    _: 1
  }))
}