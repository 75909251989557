
import {computed, defineComponent, ref, watchEffect} from 'vue'
import InputField from "@/components/utils/input/InputField.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export interface ClinicsXRayImagesFilerParams {
  clinicName: string | undefined
  cabinetName: string | undefined
  equipmentName: string | undefined
  imageName: string | undefined
}

export default defineComponent({
  name: "ClinicsXrayImagesListHeader",
  components: {InputField},
  emits: {
    onFilter: (params: unknown) => !!params
  },
  setup(_, {emit}) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)
    const activeClinicName = computed(() => clinicsState.clinics.find(c => c.uid === activeClinicUID.value)?.name)

    const clinicName = ref<string | undefined>(undefined)
    watchEffect(() => clinicName.value = activeClinicName.value)

    const cabinetName = ref<string | undefined>(undefined)
    const equipmentName = ref<string | undefined>(undefined)
    const imageName = ref<string | undefined>(undefined)
    const filterParams = computed<ClinicsXRayImagesFilerParams>(() => ({
      clinicName: clinicName.value?.toLowerCase(),
      cabinetName: cabinetName.value?.toLowerCase(),
      equipmentName: equipmentName.value?.toLowerCase(),
      imageName: imageName.value?.toLowerCase(),
    }))

    watchEffect(() => emit('onFilter', filterParams.value))
    return {
      clinicName,
      cabinetName,
      equipmentName,
      imageName
    }
  }
})
